<template>
  <div class="a-loading-skeleton" :style="{ height }" />
</template>

<script>
export default {
  name: 'ALoadingSkeleton',
  props: {
    height: {
      type: String,
      default: '100%'
    }
  }
};
</script>

<style lang="scss" scoped>
.a-loading-skeleton {
  --loading-skeleton-background: rgb(221,221,221);
  --loading-skeleton-activity: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));

  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--loading-skeleton-background);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -55%;
    width: 100%;
    height: 100%;
    background: var(--loading-skeleton-activity);
    animation: loading 1.25s infinite;
  }

  @keyframes loading {
    0% {
      transform: translateY(-55%, 0, 0);
    }

    100% {
      transform: translateY(100%, 0, 0);
    }
  }
}
</style>
