import { Logger } from '@vue-storefront/core/lib/logger';

export function getBadgesToDisplay (listOfBadges, allBadgesOptions, badgesSortOrder, formatSpecialBadgeCallback) {
  if (!listOfBadges) return
  badgesSortOrder = typeof badgesSortOrder === 'string' ? badgesSortOrder.split(',') : badgesSortOrder
  const badgeCodes = badgesSortOrder?.length ? badgesSortOrder.map(code => parseInt(code)) : listOfBadges

  return badgeCodes?.flatMap(code => {
    if (!code) {
      Logger.warn(`Badge code couldn't be established`)
      return []
    }
    const badgeOptions = allBadgesOptions.find(({ value }) => +value === +code)
    if (!badgeOptions) {
      Logger.warn(`Badge code ${code}, could not be found in badges_options attributes`)
      return []
    }
    const rawBadge = badgeOptions.label.replace(/"/g, '').split(';')
    const [rawLabel, textColor, bgColor] = rawBadge
    return {
      label: isSpecialBadge(rawLabel) ? formatSpecialBadgeCallback(rawLabel) : rawLabel,
      textColor,
      bgColor,
      rawLabel
    }
  }) || []
}

function isSpecialBadge (badgeRawLabel) {
  return ['discount_amount', 'discount_percent'].includes(badgeRawLabel)
}
