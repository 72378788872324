<template>
  <div>
    <img
      :src="`/assets/rating${score.toString().replace('.', '_')}.png?cache-version=${cacheVersion}`"
      :width="imageWidth"
      :height="imageHeight"
      :alt="Math.round(score)"
      loading="lazy"
    >
  </div>
</template>
<script>
import { mapState } from 'vuex';
import config from 'config';
import ALoadingSkeleton from '~/theme/components/atoms/a-loading-skeleton.vue'

export default {
  name: 'ARating',
  props: {
    /**
       * Maximum score
       */
    max: {
      type: Number,
      default: 5
    },
    /**
       * Score (obviously must be less than maximum)
       */
    score: {
      type: Number,
      default: 1
    },
    icon: {
      type: String,
      default: 'star'
    }
  },
  components: {
    ALoadingSkeleton
  },
  computed: {
    cacheVersion () {
      return config.localForage.cacheVersion
    },
    imageHeight () {
      return config.stampedIo.starBaseSize
    },
    imageWidth () {
      const starSize = config.stampedIo.starBaseSize * config.stampedIo.starScale
      const allStarsWidth = starSize * 5
      const allGapsWidth = config.stampedIo.gapBetweenStars * 5
      return allStarsWidth + allGapsWidth
    }
  }
};
</script>
