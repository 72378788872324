<template>
  <div class="a-product-rating">
    <div @click="scrollTo" class="product__rating product__link">
      <ARating :score="roundedScore" :max="max" />
      <div v-if="showReviewsCount" class="product__count">
        <router-link to="#additional_reviews" event="">
          <span>{{ starsScore }} / </span>
          {{
            (reviewsCount > 50 || !reviewsCount) ? $t('{count} Reviews', {count: reviewsCount || ''}) : $t('{count} Stars', {count: starsScore || ''})
          }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import ARating from '~/theme/components/atoms/a-rating.vue'

export default {
  components: {
    ARating
  },
  props: {
    reviewsCount: {
      type: Number,
      default: null
    },
    showReviewsCount: {
      type: Boolean,
      default: true
    },
    score: {
      type: Number,
      required: true
    },
    shouldLinkToReviews: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      max: 5
    }
  },
  methods: {
    scrollTo () {
      this.$bus.$emit('open-review-tab', this.$t('Reviews'))
      const review = document.getElementById('additional_reviews');
      const scrollDiv = review.offsetTop;
      window.scroll({
        top: scrollDiv,
        behavior: 'smooth'
      });
    }
  },
  computed: {
    roundedScore () {
      if (!this.score) return 5
      const step = 0.5
      return Math.round(this.score / step) * step
    },
    starsScore () {
      if (!this.score) return 5
      return parseFloat(this.score).toFixed(1)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.product {
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  &__rating {
    --icon-color: black;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--font-size--xs);
    @include for-mobile {
      flex-direction: row;
    }
  }
  &__count {
    color: var(--c-text-primary);
    margin: var(--spacer-xs) 0;
    text-align: center;
    text-decoration: none;
    a {
      font-weight: 600;
      span {
        display: none;
      }
    }
    @include for-mobile {
      margin-top: 0;
    }
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
#product {
  .product__count a span {
    display: inline-block;
    padding-left: 4px;
  }
}
</style>
